import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Button from "../../../components/button/Button";
import CustomColorPicker from "../../../components/colorpicker/CustomColorPicker";
import TextInput from "../../../components/inputs/TextInput";
import LoadingAnimation from "../../../components/LoadingAnimation/LoadingAnimation";
import RequireAuth from "../../../components/RequireAuth";
import { Role } from "../../../enums/Role";
import ProjectConfigDto from "../../../interfaces/ProjectConfig.dto";
import projectConfigStore from "../../../stores/project-config.store";
import { onPromise } from "../../../utils/formHelper";
import { isUserReadyonly } from "../../../utils/KeycloakUser.util";
import ToggleSwitch from "../../../components/toggle-switch/ToggleSwitch";

const Customization = () => {
  const [isLoading, setIsLoading] = useState(false);
  const readOnly = isUserReadyonly(useKeycloak().keycloak);

  const methods = useForm<any>({
    mode: "onSubmit",
    defaultValues: useMemo(() => projectConfigStore.config, []),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!methods.watch("primaryColor")) {
      methods.setValue("primaryColor", "#00854d");
    }
    if (!methods.watch("secondaryColor")) {
      methods.setValue("secondaryColor", "#0d0d0d");
    }
    if (!methods.watch("backgroundColor")) {
      methods.setValue("backgroundColor", "#edf2ef");
    }
  });

  const onSubmit: SubmitHandler<ProjectConfigDto> = async (data) => {
    try {
      await setIsLoading(true);
      await projectConfigStore.updateConfig(data);
      await setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) {
    return (
      <div className="h-full w-full p-4 flex justify-center items-center">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <div className="h-full w-full p-6">
      <FormProvider {...methods}>
        <form onSubmit={onPromise(methods.handleSubmit(onSubmit))}>
          <div className="text-xl font-bold">Customization</div>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Configure the look of the widget
          </p>
          <div className="mt-4 max-w-2xl">
            <div>
              <div className="text-xl border p-4 rounded-md">
                <div className="text-xl font-semibold">Colors</div>
                <div className="w-full mt-4">
                  <CustomColorPicker
                    title="Primary Color"
                    name="primaryColor"
                    readOnly={readOnly}
                  />
                  <CustomColorPicker
                    title="Secondary Color"
                    name="secondaryColor"
                    readOnly={readOnly}
                  />
                  <CustomColorPicker
                    title="Background Color"
                    name="backgroundColor"
                    readOnly={readOnly}
                  />

                  <div className="flex items-center mt-4">
                    <ToggleSwitch
                      initialyEnabled={methods.watch("darkMode")}
                      key={"toggleDarkMode"}
                      readOnly={readOnly}
                      onChange={(isEnabled) => {
                        methods.setValue("darkMode", isEnabled);
                      }}
                    />
                    <div className="text-lg font-bold ml-4">
                      Enable Dark Mode
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-xl border p-4 rounded-md mt-4">
                <div className="text-xl font-semibold">
                  Privacy and Terms of Service
                </div>
                <div className="w-full mt-4">
                  <p className="mt-1 max-w-2xl text-sm mb-3 text-gray-500">
                    Privacy Policy
                  </p>
                  <TextInput
                    className="w-full"
                    variable="privacyPolicyURL"
                    placeHolder="Privacy URL"
                    regExp={/^https?:\/\/.*/}
                    readOnly={readOnly}
                  />
                  <p className="mt-1 max-w-2xl text-sm mb-3 text-gray-500">
                    Terms of Service
                  </p>
                  <TextInput
                    className="w-full"
                    variable="termsOfServiceURL"
                    placeHolder="Terms of Service URL"
                    regExp={/^https?:\/\/.*/}
                    readOnly={readOnly}
                    required={false}
                  />
                  <p className="mt-1 max-w-2xl text-sm mb-3 text-gray-500">
                    Imprint
                  </p>
                  <TextInput
                    className="w-full"
                    variable="imprintURL"
                    placeHolder="Imprint URL"
                    regExp={/^https?:\/\/.*/}
                    readOnly={readOnly}
                  />
                </div>
              </div>
              <RequireAuth roles={[Role.ADMIN]}>
                <div className="flex justify-end">
                  <Button
                    className="mt-4 w-64"
                    type="submit"
                    title="Save"
                    style="primary"
                  />
                </div>
              </RequireAuth>
            </div>
          </div>
        </form>
      </FormProvider>
      <div className="h-16"></div>
    </div>
  );
};

export default Customization;
